@import "tabler/tabler";

.custom-select, .dataTables_wrapper .dataTables_length select {
  -moz-appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='%23999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
}

.header {
  background-color: #25272D;

  .text-default {
    color: #F2F2F2 !important;
  }
  .text-muted {
    color: #D2D2D2 !important;
  }
}
#headerMenuCollapse {
  background-color: #FFF;
}
.page.login {
  background-color: #25272D;
  background-image: url("/img/bg.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

.card {
  box-sizing: content-box !important;
}
.card::before, .card::after {
  box-sizing: content-box !important;
}

.dataTables_wrapper {
  position: relative;

  max-width: 100%;
  overflow-x: auto;

  table.datatable {
    max-width:100%;
  }

  .dataTables_processing {
    //background-color: rgba(255, 255, 255, .8);
    display: block;
    position: absolute;
    z-index: 20;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 46px;
  }
}

tr.stripe td {
  background-color: rgba(0, 0, 0, 0.02);
}

canvas.signature-canvas {
  border: 1px solid #e0e5ec;

  &.has-error {
    border: 1px solid #cd201f;
  }
}
.table-striped-tbody tbody:nth-of-type(odd) tr {
  background-color:rgba(0,0,0,0.02);
}

.intake-table progress[value="0"] {
  display: none;
}
[v-cloak] {
  display: none;
}